
export default {
  name: 'base-header-mobile',
  props: {
    menu: { type: Array, default: () => [] },
    contacts: { type: Array, default: () => [] },
  },
  data () {
    return {
      active: false,
    };
  },
  computed: {
    classes () {
      return {
        'base-header-mobile--active': this.active,
      };
    },
    currentRoute () {
      return this.$route.fullPath;
    },
  },
  watch: {
    currentRoute: {
      handler () {
        this.closeMenu();
      },
    },
  },
  methods: {
    toggleActive () {
      this.active = !this.active;
      if (this.active) this.hideOverflow();
      else this.showOverflow();
    },
    closeMenu () {
      this.active = false;
      this.showOverflow();
    },
    goToContactForm () {
      this.closeMenu();
      this.$emit('go-to-contacts');
    },
  },
};
