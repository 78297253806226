
import { mapGetters } from 'vuex';

export default {
  name: 'base-header',
  props: {
    whiteMode: { type: Boolean, default: false },
  },
  data () {
    return {
      contacts: [
        { value: 'cargo@hkok.ru', link: `mailto:cargo@hkok.ru`, icon: 'icon-mail' },
      ],
    };
  },
  computed: {
    ...mapGetters('sulu', ['menu']),
    navigation () {
      return this.menu.slice(0, 3);
    },
    classes () {
      return {
        'base-header--light': this.whiteMode,
        'base-header--active': this.active,
      };
    },
  },
  methods: {
    scrollToContact () {
      const contactEl = document.querySelector('#contactUs');
      if (!contactEl) return;

      this.$forceNextTick(() => {
        window.scrollTo({
          top: contactEl.offsetTop,
          behavior: 'smooth',
        });
      });
    },
  },
};
