import { render, staticRenderFns } from "./mobile.vue?vue&type=template&id=3a0b1830"
import script from "./mobile.vue?vue&type=script&lang=js"
export * from "./mobile.vue?vue&type=script&lang=js"
import style0 from "./mobile.vue?vue&type=style&index=0&id=3a0b1830&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseLogo: require('/opt/hkok.ru/src/components/base/logo.vue').default,BaseNavItem: require('/opt/hkok.ru/src/components/base/nav-item.vue').default,RzButton: require('/opt/hkok.ru/node_modules/razlet-ui/src/components/button/index.js').default,RzContainer: require('/opt/hkok.ru/node_modules/razlet-ui/src/components/container/index.vue').default})
